import React, { Component } from 'react'
import Layout from '../components/layout'
import styled from 'styled-components'
import { FaArrowDown } from 'react-icons/fa'

const CaseWrap = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 10%;
`

const CaseHeader = styled.h1`
  font-size: 48px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 3%;
`

const CaseSubHeader = styled.h3`
  font-size: 36px;
  text-align: center;
  font-weight: bold;
`

const CaseBodyCopy = styled.p`
  font-size: 24px;
  text-align: center;
`

export default class cases extends Component {
  render() {
    const data = require('../components/case studies/case-study.json')

    return (
      <Layout>
        {data.casegroup.map((d, i) => (
          <CaseWrap
            style={{ backgroundColor: d.background_color }}
            key={d.title}
            id={`section-${i}`}
          >
            <div id="case-study" />
            <CaseHeader style={{ color: d.text_colour }}>{d.title}</CaseHeader>
            <CaseSubHeader style={{ color: d.text_colour }}>
              The Requirement
            </CaseSubHeader>
            <CaseBodyCopy style={{ color: d.text_colour }}>
              {d.the_requirement}
            </CaseBodyCopy>
            <CaseSubHeader style={{ color: d.text_colour }}>
              The Solution
            </CaseSubHeader>
            <CaseBodyCopy style={{ color: d.text_colour }}>
              {d.the_solution}
            </CaseBodyCopy>
            <CaseSubHeader style={{ color: d.text_colour }}>
              The Result
            </CaseSubHeader>
            <CaseBodyCopy style={{ color: d.text_colour }}>
              {d.the_result}
            </CaseBodyCopy>
            <a href={`#section-${i + 1}`}>
              <FaArrowDown
                style={{
                  color: d.text_colour,
                  fontSize: 64,
                  textAlign: 'center',
                  marginTop: '5%',
                }}
              />
            </a>
          </CaseWrap>
        ))}
      </Layout>
    )
  }
}
